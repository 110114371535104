@import '../../global.scss';

.serve-container{
    position: relative;
    width: 100%;
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    gap: 40px;
    flex-wrap: wrap;
  }
  
  .serve-container .cardserv{
    position: relative;
    width: 320px;
    height: 450px;
    background: linear-gradient(130deg, #ffd8e9 0%, #e1faff 100%);
    border-radius: 20px;
    /* overflow: hidden; */
  }
  
  .serve-container .cardserv:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, #1400C8 0%, #00B9FF 100%);
    clip-path: circle(300px at 80% -20%);
    transition: 0.5s ease-in-out;
}

.serve-container .cardserv:hover:before{
      clip-path: circle(150px at 80% 20%);
  }
  
  
  
  .serve-container .cardserv .imgBx{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    // z-index: 10000;
    width: 100%;
    height: 250px;
    transition: 0.5s;
  }
  
  .serve-container .cardserv:hover .imgBx{
    top: 50%;
    transform: translateY(-70%);
  }
  
  .serve-container .cardserv .imgBx img{
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(00deg);
    width: 200px;
    transition: 0.5s;
  }
  .serve-container .cardserv:hover .imgBx img{
    transform: translate(-50%, -50%) rotate(-20deg);
  }
  
  .serve-container .cardserv .contentBx{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    text-align: center;
    transition: 1s;
    z-index: 10;
  }
  
  .serve-container .cardserv .contentBx{
    height: 250px;
  }
  
  .serve-container .cardserv .contentBx h2{
    position: relative;
    font-size: 2em;
    top: 90px;
    font-weight: 600;
    letter-spacing: 1px;
    background: linear-gradient(227deg, #B900B4 0%, #F50000 100%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
    margin: 0;
    transition: 0.5s;
  }
  .serve-container .cardserv:hover .contentBx h2{
    position: relative;
    font-size: 2em;
    top: 0px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0;
    opacity: 0;
  }
  
  
  .serve-container .cardserv .contentBx .size, .serve-container .cardserv .contentBx .color {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    transition: 0.5s;opacity: 0;
    visibility: hidden;
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .serve-container .cardserv:hover .contentBx .size{
    opacity: 1;
    visibility: visible;
    transition-delay: 0.5s;
  }
  
  .serve-container .cardserv .contentBx .size h3{
    color: #000;
    font-weight: 300;
    font-size: 18px;
    // text-transform: uppercase;
    // letter-spacing: 2px;
    margin-right: 10px;
  }
  
  .serve-container .cardserv .contentBx
  {
    svg{
      bottom: 30px;
      left: 30px;
      position: absolute;
      font-size: 2rem;
      color: #000;
    }
    a{
      display: inline-block;
      padding: 10px 20px;
      background: linear-gradient(227deg, #1400C8 0%, #00B9FF 100%);
      border-radius: 4px;
      margin-top: 10px;
      text-decoration: none;
      font-weight: 600;
      color: #fff;
      width: 180px;
      left: 50%;
      transform: translateX(-50%);
      transition: 0.5s;
      margin-top: 0;
      bottom: 20px;
      position: absolute ;
    }
  }
    // linear-gradient(227deg, #1400C8 0%, #00B9FF 100%)
// linear-gradient(to left, #da4453, #89216b)
// linear-gradient(227deg, #F50000 0%, #FFD521 100%)
// linear-gradient(227deg, #B900B4 0%, #F50000 100%)
    .serve-container .cardserv:nth-child(1)
    {
        .contentBx a{
            background: linear-gradient(227deg, #1400C8 0%, #00B9FF 100%);
            color: #fff;
        }
        .contentBx h2 {
            background: linear-gradient(227deg, #1400C8 0%, #00B9FF 100%);
            background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
        }
        &:before{
            background: linear-gradient(227deg, #1400C8 0%, #00B9FF 100%);
        }
    } 
    .serve-container .cardserv:nth-child(2)
    {
        .contentBx a{
            background: linear-gradient(227deg, #B900B4 0%, #F50000 100%);
            color: #fff;
        }
        .contentBx h2 {
            background: linear-gradient(227deg, #B900B4 0%, #F50000 100%);
            background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
        }
        &:before{
            background: linear-gradient(to left, #B900B4 0%, #F50000 100%);
        }
    }
    .serve-container .cardserv:nth-child(3)
    {
        .contentBx a{
            background: linear-gradient(227deg, #F50000 0%, #FFD521 100%);
            color: #fff;
        }
        .contentBx h2 {
            background: linear-gradient(227deg, #F50000 0%, #FFD521 100%);
            background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
        }
        &:before{
            background: linear-gradient(227deg, #F50000 0%, #FFD521 100%);
        }
    }
    .serve-container .cardserv:nth-child(4)
    {
        .contentBx a{
            background: linear-gradient(to left, #da4453, #89216b);
            color: #fff;
        }
        .contentBx h2 {
            background: linear-gradient(to left, #da4453, #89216b);
            background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
        }
        &:before{
            background: linear-gradient(to left, #da4453, #89216b);
        }
        
    }

  