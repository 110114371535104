@import '../../global.scss';
.modal111 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
    .modal-main {

        --border-width: 3px;
        border-radius: 2rem;
        position: fixed;
        padding: 2rem;
        background-color: white;
        width: 950px;
        @include mobile {
            width: 80%;
            padding: 1rem;
        }
        min-height: 300px;
        z-index: 1051;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        .modal-header {
            background-color: #fff;
            h1 {
                text-align: center;
                @include mobile {
                    font-size: 1.8rem
                }
            }
        }
        .modal-body {
            width: 100%;
            // height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: aquamarine;
        }
        .modal-cta {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        .modal-footer {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
           a {
                color: blue;
                text-decoration: underline;
                position: absolute !important;
                bottom: 0;
                margin-top: 1rem;
           }
        }
        // &::before {
        //     position: absolute;
        //     content: "";
        //     top: calc(-1 * var(--border-width));
        //     left: calc(-1 * var(--border-width));
        //     z-index: -10;
        //     width: calc(100% + var(--border-width) * 2);
        //     height: calc(100% + var(--border-width) * 2);
        //     background: linear-gradient(
        //       60deg,
        //       hsl(224, 85%, 66%),
        //       hsl(269, 85%, 66%),
        //       hsl(314, 85%, 66%),
        //       hsl(359, 85%, 66%),
        //       hsl(44, 85%, 66%),
        //       hsl(89, 85%, 66%),
        //       hsl(134, 85%, 66%),
        //       hsl(179, 85%, 66%)
        //     );
        //     background-size: 300% 300%;
        //     background-position: 0 50%;
        //     border-radius: 2rem;
        //     animation: moveGradient 4s alternate infinite;
        //   }
    }
    .close {
        position: absolute;
        top: 30px;
        right: 30px;
        font-size: 2rem;
        cursor: pointer;
        color: #fff;
        opacity: 1;
    }
}

@keyframes moveGradient {
    50% {
      background-position: 100% 50%;
    }
  }