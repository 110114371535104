@import url("https://fonts.googleapis.com/css?family=Roboto:700");
body {
  font-family: 'Roboto', sans-serif;
//   background:  linear-gradient(130deg,rgba(255,216,233,1) 10%, rgba(255,255,255,1) 26%,rgba(255,255,255,1) 60%, rgba(225,250,255,1) 100%);
//   background-size: 800% 800%;
//   animation: gradient 10000ms ease infinite;
}

.loader-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 auto;
  width: 50%;
}

@-webkit-keyframes loader-rotate {
  0% {
    -webkit-transform: rotateX(-37.5deg) rotateY(45deg);
            transform: rotateX(-37.5deg) rotateY(45deg);
  }
  50% {
    -webkit-transform: rotateX(-37.5deg) rotateY(405deg);
            transform: rotateX(-37.5deg) rotateY(405deg);
  }
  100% {
    -webkit-transform: rotateX(-37.5deg) rotateY(405deg);
            transform: rotateX(-37.5deg) rotateY(405deg);
  }
}

@keyframes loader-rotate {
  0% {
    -webkit-transform: rotateX(-37.5deg) rotateY(45deg);
            transform: rotateX(-37.5deg) rotateY(45deg);
  }
  50% {
    -webkit-transform: rotateX(-37.5deg) rotateY(405deg);
            transform: rotateX(-37.5deg) rotateY(405deg);
  }
  100% {
    -webkit-transform: rotateX(-37.5deg) rotateY(405deg);
            transform: rotateX(-37.5deg) rotateY(405deg);
  }
}

.loader-cube, .loader-cube * {
  position: absolute;
  width: 151px;
  height: 151px;
}

.loader-sides {
  -webkit-animation: loader-rotate 3s ease infinite;
          animation: loader-rotate 3s ease infinite;
  -webkit-animation-delay: .8s;
          animation-delay: .8s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: rotateX(-37.5deg) rotateY(45deg);
          transform: rotateX(-37.5deg) rotateY(45deg);
}

.loader-cube .loader-sides * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.5);
  border: 3px solid rgba(0, 0, 0, 0.9);
}

.loader-cube .loader-sides .loader-top {
  -webkit-animation: loader-top-animation 3s ease infinite;
          animation: loader-top-animation 3s ease infinite;
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
  -webkit-transform: rotateX(90deg) translateZ(150px);
          transform: rotateX(90deg) translateZ(150px);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

@-webkit-keyframes loader-top-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(150px);
            transform: rotateX(90deg) translateZ(150px);
  }
  20% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(75px);
            transform: rotateX(90deg) translateZ(75px);
  }
  70% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(75px);
            transform: rotateX(90deg) translateZ(75px);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(150px);
            transform: rotateX(90deg) translateZ(150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(150px);
            transform: rotateX(90deg) translateZ(150px);
  }
}

@keyframes loader-top-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(150px);
            transform: rotateX(90deg) translateZ(150px);
  }
  20% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(75px);
            transform: rotateX(90deg) translateZ(75px);
  }
  70% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(75px);
            transform: rotateX(90deg) translateZ(75px);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(150px);
            transform: rotateX(90deg) translateZ(150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateX(90deg) translateZ(150px);
            transform: rotateX(90deg) translateZ(150px);
  }
}

.loader-cube .loader-sides .bottom {
  -webkit-animation: bottom-animation 3s ease infinite;
          animation: bottom-animation 3s ease infinite;
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
  -webkit-transform: rotateX(-90deg) translateZ(150px);
          transform: rotateX(-90deg) translateZ(150px);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

@-webkit-keyframes bottom-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(150px);
            transform: rotateX(-90deg) translateZ(150px);
  }
  20% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(75px);
            transform: rotateX(-90deg) translateZ(75px);
  }
  70% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(75px);
            transform: rotateX(-90deg) translateZ(75px);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(150px);
            transform: rotateX(-90deg) translateZ(150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(150px);
            transform: rotateX(-90deg) translateZ(150px);
  }
}

@keyframes bottom-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(150px);
            transform: rotateX(-90deg) translateZ(150px);
  }
  20% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(75px);
            transform: rotateX(-90deg) translateZ(75px);
  }
  70% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(75px);
            transform: rotateX(-90deg) translateZ(75px);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(150px);
            transform: rotateX(-90deg) translateZ(150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateX(-90deg) translateZ(150px);
            transform: rotateX(-90deg) translateZ(150px);
  }
}

.loader-cube .loader-sides .front {
  -webkit-animation: front-animation 3s ease infinite;
          animation: front-animation 3s ease infinite;
  -webkit-animation-delay: 100ms;
          animation-delay: 100ms;
  -webkit-transform: rotateY(0deg) translateZ(150px);
          transform: rotateY(0deg) translateZ(150px);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

@-webkit-keyframes front-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(150px);
            transform: rotateY(0deg) translateZ(150px);
  }
  20% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(75px);
            transform: rotateY(0deg) translateZ(75px);
  }
  70% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(75px);
            transform: rotateY(0deg) translateZ(75px);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(150px);
            transform: rotateY(0deg) translateZ(150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(150px);
            transform: rotateY(0deg) translateZ(150px);
  }
}

@keyframes front-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(150px);
            transform: rotateY(0deg) translateZ(150px);
  }
  20% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(75px);
            transform: rotateY(0deg) translateZ(75px);
  }
  70% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(75px);
            transform: rotateY(0deg) translateZ(75px);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(150px);
            transform: rotateY(0deg) translateZ(150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(150px);
            transform: rotateY(0deg) translateZ(150px);
  }
}

.loader-cube .loader-sides .back {
  -webkit-animation: back-animation 3s ease infinite;
          animation: back-animation 3s ease infinite;
  -webkit-animation-delay: 100ms;
          animation-delay: 100ms;
  -webkit-transform: rotateY(-180deg) translateZ(150px);
          transform: rotateY(-180deg) translateZ(150px);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

@-webkit-keyframes back-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(150px);
            transform: rotateY(-180deg) translateZ(150px);
  }
  20% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(75px);
            transform: rotateY(-180deg) translateZ(75px);
  }
  70% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(75px);
            transform: rotateY(-180deg) translateZ(75px);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(150px);
            transform: rotateY(-180deg) translateZ(150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(150px);
            transform: rotateY(-180deg) translateZ(150px);
  }
}

@keyframes back-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(150px);
            transform: rotateY(-180deg) translateZ(150px);
  }
  20% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(75px);
            transform: rotateY(-180deg) translateZ(75px);
  }
  70% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(75px);
            transform: rotateY(-180deg) translateZ(75px);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(150px);
            transform: rotateY(-180deg) translateZ(150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateY(-180deg) translateZ(150px);
            transform: rotateY(-180deg) translateZ(150px);
  }
}

.loader-cube .loader-sides .left {
  -webkit-animation: left-animation 3s ease infinite;
          animation: left-animation 3s ease infinite;
  -webkit-animation-delay: 100ms;
          animation-delay: 100ms;
  -webkit-transform: rotateY(-90deg) translateZ(150px);
          transform: rotateY(-90deg) translateZ(150px);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

@-webkit-keyframes left-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(150px);
            transform: rotateY(-90deg) translateZ(150px);
  }
  20% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(75px);
            transform: rotateY(-90deg) translateZ(75px);
  }
  70% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(75px);
            transform: rotateY(-90deg) translateZ(75px);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(150px);
            transform: rotateY(-90deg) translateZ(150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(150px);
            transform: rotateY(-90deg) translateZ(150px);
  }
}

@keyframes left-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(150px);
            transform: rotateY(-90deg) translateZ(150px);
  }
  20% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(75px);
            transform: rotateY(-90deg) translateZ(75px);
  }
  70% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(75px);
            transform: rotateY(-90deg) translateZ(75px);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(150px);
            transform: rotateY(-90deg) translateZ(150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateY(-90deg) translateZ(150px);
            transform: rotateY(-90deg) translateZ(150px);
  }
}

.loader-cube .loader-sides .loader-right {
  -webkit-animation: loader-right-animation 3s ease infinite;
          animation: loader-right-animation 3s ease infinite;
  -webkit-animation-delay: 100ms;
          animation-delay: 100ms;
  -webkit-transform: rotateY(90deg) translateZ(150px);
          transform: rotateY(90deg) translateZ(150px);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

@-webkit-keyframes loader-right-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(150px);
            transform: rotateY(90deg) translateZ(150px);
  }
  20% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(75px);
            transform: rotateY(90deg) translateZ(75px);
  }
  70% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(75px);
            transform: rotateY(90deg) translateZ(75px);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(150px);
            transform: rotateY(90deg) translateZ(150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(150px);
            transform: rotateY(90deg) translateZ(150px);
  }
}

@keyframes loader-right-animation {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(150px);
            transform: rotateY(90deg) translateZ(150px);
  }
  20% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(75px);
            transform: rotateY(90deg) translateZ(75px);
  }
  70% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(75px);
            transform: rotateY(90deg) translateZ(75px);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(150px);
            transform: rotateY(90deg) translateZ(150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateY(90deg) translateZ(150px);
            transform: rotateY(90deg) translateZ(150px);
  }
}

.text {
  margin-top: 450px;
  color: #f27777;
  font-size: 1.5rem;
  width: 100%;
  font-weight: 600;
  text-align: center;
}