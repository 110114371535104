@import '../../global.scss';
.privacy {
    margin-top: 5rem;
    margin-left: 5rem;
    margin-right: 5rem;
    @include mobile {
        margin-left: 2rem;
        margin-right: 2rem;
    }
    p {
        margin-left: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    h1 {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}