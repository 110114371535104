@import "../../global.scss";
$color_1: #222327;
$color_2: #2196f3;
$color_3: #fff;

.MagicBar {
    position: fixed;
    bottom: 0;
    z-index: 999;
    width: 100vw;
    box-shadow: rgba(0, 0, 0, 0.1) 0px -10px 15px -3px, rgba(0, 0, 0, 0.05) 0px -4px 6px -2px;

    .navigation {
        width: 100vw;
        position: relative;
        height: 60px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;

        ul {
            width: 90vw;
            display: flex;

            li {
                list-style: none;
                position: relative;
                width: 18vw;
                height: 60px;
                z-index: 10000;

                a {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;
                    text-align: center;
                    font-size: 500;

                    .icon {
                        position: relative;
                        display: block;
                        line-height: 65px;
                        font-size: 1.5rem;
                        transition: 0.5s;
                        color: $color_1;
                    }

                    .texta {
                        position: absolute;
                        background: #2196f3;
                        color: $color_3;
                        padding: 2px 7px;
                        border-radius: 12px;
                        font-weight: 400;
                        font-size: 0.75rem;
                        letter-spacing: 0.05rem;
                        // transition: 0.5rem;
                        opacity: 0;
                        transform: translateY(15px);
                    }
                }

                &:nth-child(1).active {
                    ~ {
                        .indicator {
                            transform: translateX(calc(18vw * 0));
                        }
                    }
                }

                &:nth-child(2).active {
                    ~ {
                        .indicator {
                            transform: translateX(calc(18vw * 1));
                        }
                    }
                }

                &:nth-child(3).active {
                    ~ {
                        .indicator {
                            transform: translateX(calc(18vw * 2));
                        }
                    }
                }

                &:nth-child(4).active {
                    ~ {
                        .indicator {
                            transform: translateX(calc(18vw * 3));
                        }
                    }
                }

                &:nth-child(5).active {
                    ~ {
                        .indicator {
                            transform: translateX(calc(18vw * 4));
                        }
                    }
                }
            }

            li.active {
                a {
                    .icon {
                        transform: translateY(-32px);
                        color: $color_2;
                    }
                }

                a {
                    .texta {
                        transform: translateY(-4px);
                        opacity: 1;
                    }
                }
            }
        }
    }

    .indicator {
        position: absolute;
        box-shadow: rgba(0, 0, 0, 0.1) 0px -10px 15px -3px, rgba(0, 0, 0, 0.05) 0px -4px 6px -2px;
        top: -35px;
        width: 18vw;
        height: 70px;
        background: #fff;
        border-radius: 50%;
        z-index: 1;
        transition: 0.5s;

        &::before {
            content: '';
            position: absolute;
            top: 5px;
            left: -28px;
            width: 30px;
            height: 30px;
            background: transparent;
            border-radius: 50%;
            box-shadow: 15px 18px #fff;
        }

        &::after {
            content: '';
            position: absolute;
            top: 5px;
            right: -28px;
            width: 30px;
            height: 30px;
            background: transparent;
            border-radius: 50%;
            box-shadow: -15px 18px #fff;
        }
    }

}

.hidden {
    display: none;
}