@import '../../global.scss';

.dropitsingle {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #ff4e00;
    background-image: linear-gradient(227deg, #ff4e00 0%, #ec9f05 74%);
    // overflow-x: hidden;
//     // overflow-y: hidden;
// .wrapper {
//     scale: 0.8;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     width: 100%;
//     height: 100%;
//     position: relative;
//     bottom: 10vh;
//     // top: 0;
// }
    .sun {
        position: absolute;
        display: inline-block;

        bottom: -150px;
        width: 400px;
        height: 400px;
        background: orange;
        box-shadow: 0 0 10px orange,
            0 0 60px orange,
            0 0 200px yellow,
            inset 0 0 80px yellow;
        border-radius: 50%;
    }

    h2 {
        margin: 20px;
        color: #fff;
        font-size: 1.5rem;
    }

    .game-container {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100%;
        height: 100%;
        flex-direction: column;

        .option-column {
            display: flex;
            align-items: center;
            flex-direction: column;

            h2 {
                margin: 10px;
            }

            .option-container {
                background-color: #ffffff0f;
                border: 1px solid rgba(255, 255, 255, 0.1);
                border-radius: 15px;
                // backdrop-filter: blur(30px);
                box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
                padding: 4px;
                width: 100%;
                min-height: 64px;
                display: flex;
                border-radius: 25px;
                flex-wrap: wrap;
                justify-content: center;

                .draggable {
                    user-select: none;
                    padding: 10px;
                    // min-height: 50px;
                    width: 130px;
                    margin: 8px;
                    color: #000;
                    border-radius: 25px;
                    text-align: center;
                }
            }
        }

        .questions {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .column-question {
                display: flex;
                align-items: center;

                h3 {
                    color: #fff;
                    margin: 5px;
                }

                .blank-container {
                    background-color: rgba(255, 255, 255, 0.06);
                    border: 1px solid rgba(255, 255, 255, 0.1);
                    border-radius: 15px;
                    // backdrop-filter: blur(30px);
                    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
                    padding: 4px;
                    width: 150px;
                    height: 64px;
                    display: flex;
                    border-radius: 25px;
                    align-items: center;
                    justify-content: center;

                    .draggable {
                        user-select: none;
                        padding: 10px;
                        border-radius: 25px;
                        // // min-height: 50px;
                        width: 130px;
                        // margin: 8px;
                        text-align: center;
                        color: #000;
                    }
                }
            }
        }
    }

    .sub-butt {
        background-color: rgba(255, 255, 255, 0.06);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 15px;
        // backdrop-filter: blur(30px);
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
        margin-top: 1.5rem;
        padding: 14px;
        color: #fff;
        font-family: "Righteous";
        cursor: pointer;

        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
}