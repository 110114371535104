@import '../../global.scss';

.quiz {
    min-height: 100vh;
    margin-top: 6rem;

    @include mobile {
        margin-top: 130px;
    }

    .container {
        max-width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        min-height: 500px;
        align-items: center;
        justify-content: space-evenly;

        @include mobile {
            padding: 0 15px;

            .content {
                padding-bottom: 20px;

            }

            .image {
                margin-bottom: 50px;
            }
        }

        .content {
            max-width: 600px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .main-head {
                background: linear-gradient(227deg, #B900B4 0%, #F50000 100%);
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;
                box-decoration-break: clone;
                -webkit-box-decoration-break: clone;
            }

            h2 {
                font-family: 'Hey Comic';
                font-weight: 400;
                font-size: 3rem;
                margin: 20px 0px;
                color: inherit;
                text-align: center;
            }

            p {
                color: #000;
                text-align: center;
                font-family: 'Righteous';
                font-size: larger;

                @include mobile {
                    font-size: 18px;
                }

            }

            .dates {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 20px;

                .box {
                    margin: 15px;
                    position: relative;
                    width: 200px;
                    height: 150px;
                    display: flex;
                    border-radius: 15px;
                    background-color: #fff;
                    z-index: 0;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    -webkit-box-shadow: 10px 10px 20px 0px black;
                    -moz-box-shadow: 10px 10px 20px 0px black;
                    box-shadow: 10px 10px 20px 0px black;

                    @include mobile {
                        width: 150px;
                        height: 100px;
                    }

                    .title-1 {
                        position: absolute;
                        top: 0;
                        background: #f7c500;
                        padding: 5px 10px;
                        border-radius: 0px 0px 8px 8px;
                        font-weight: 600;
                        color: #ffffff;
                        font-size: 1.5rem;

                        @include mobile {
                            padding: 0px 10px;
                        }

                    }

                    .details {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        h2 {
                            position: relative;
                            top: 20px;
                            font-size: 2.5rem;

                            @include mobile {
                                font-size: 2rem;
                            }
                        }
                    }
                }
            }

            .butts {
                display: flex;
                align-items: center;
                justify-content: center;
                // flex-direction: column;
                gap: 10px;

                .button-6 {
                    margin-top: 20px;
                    align-items: center;
                    background-color: #F50000;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    border-radius: .25rem;
                    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
                    box-sizing: border-box;
                    color: rgba(255, 255, 255, 1);
                    cursor: pointer;
                    display: inline-flex;
                    font-size: 24px;
                    font-weight: 600;
                    justify-content: center;
                    line-height: 1.25;
                    min-height: 4rem;
                    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
                    position: relative;
                    text-decoration: none;
                    text-align: center;
                    transition: all 250ms;
                    user-select: none;
                    -webkit-user-select: none;
                    touch-action: manipulation;
                    vertical-align: baseline;
                    width: 10rem;
                }

                .button-6:hover,
                .button-6:focus {
                    border-color: rgba(0, 0, 0, 0.15);
                    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
                    color: rgba(255, 255, 255, 1);
                }

                .button-6:hover {
                    transform: translateY(-1px);
                }

                .button-6:active {
                    background-color: #0000ff;
                    border-color: rgba(0, 0, 0, 0.15);
                    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
                    color: rgb(0, 0, 0);
                    transform: translateY(0);
                }
            }

            ul {
                li {
                    font-size: larger;
                    font-family: 'Righteous';
                    color: #fff;
                    list-style-type: disc;
                }
            }
        }

        .image {
            img {
                height: 450px;

                width: 450px;

                @include mobile {
                    height: 300px;
                    width: 300px;
                }
            }
        }
    }

    .container:nth-child(1) {
        background-color: #ffffff;
        color: #000;

    }

    .container:nth-child(2) {
        color: #fff;
        background-color: #fbb016;
        flex-direction: row-reverse;

    }

    .container:nth-child(3) {
        background: linear-gradient(to bottom, #0B4778, #16B1DE);
        color: #fff;
    }

    .marquee {
        width: 100vw;
        line-height: 50px;
        position: fixed;
        top: 0;
        background-color: #13cfb8;
        color: white;
        white-space: nowrap;
        overflow: hidden;
        box-sizing: border-box;
        z-index: 5;

        // transition: all 10s linear ;
        @include mobile {
            line-height: 30px;
            bottom: auto;
        }
    }

    .marquee p {

        display: inline-block;
        padding-left: 100%;
        color: rgb(0, 0, 0);
        font-size: 1.5rem;
        font-weight: 500;
        animation: marquee 15s linear infinite;

        @include mobile {
            font-size: 17px;
        }

        a {
            color: #B900B4;
        }
    }

    @keyframes marquee {
        0% {
            transform: translate(0, 0);
        }

        100% {
            transform: translate(-100%, 0);
        }
    }
}