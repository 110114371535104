.micro {
    margin-top: 35px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 90%;
        max-width: 700px;
    }
}