@import '../../global.scss';

.signup {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    flex-direction: column;
    background: linear-gradient(130deg, #ffd8e9 0%, #e1faff 100%);
    .home {
        position: absolute;
        top: 0;
        left: 0;
        margin: 1rem;
        color: #fff;
        font-size: 1.5rem;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        a {
            text-decoration: none;
            color: #000;
        }
        &:hover a {
            color: #fff;
        }
    }
    .signup-container {
        -webkit-box-shadow: 6px 10px 26px -4px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 6px 10px 26px -4px rgba(0, 0, 0, 0.75);
        box-shadow: 6px 10px 26px -4px rgba(0, 0, 0, 0.75);
        background-color: #fff;
        border-radius: 2rem;
        padding: 1rem 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        max-width: 500px;

        .signup-header {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;

            h1 {
                font-size: 2rem;
                margin-bottom: 1rem;
            }

            p {
                font-size: 1.2rem;
                margin-bottom: 1rem;
                text-align: center;
            }
        }

        .signup-form {
            display: flex;
            flex-direction: column;

            .btn-primary {
                // margin-top: 1rem;
                padding: 0.5rem;
                border-radius: 20px;
                margin-bottom: 1rem;
                &:disabled {
                    background-color: #ccc;
                    color: #fff;
                    border-color: #ccc;
                }
            }

            input {
                margin-bottom: 1rem;
                padding: 0.5rem;
                border: 1px solid #ccc;
                border-radius: 5px;
                outline: none;

                &:focus {
                    border: 3px solid rgb(31, 53, 173);
                }
            }

            select {
                margin-bottom: 1rem;
                padding: 0.5rem;
                border: 1px solid #ccc;
                border-radius: 5px;
                outline: none;

                &:focus {
                    border: 3px solid rgb(31, 53, 173);
                }
            }

            .phone {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                width: 100%;

                label {
                    font-size: larger;
                    margin-bottom: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg {
                        margin-right: 0.5rem;
                        color: green;
                        font-size: 1.5rem;
                    }
                }

                input {
                    width: 100%;
                    margin-left: 0.25rem;
                }
            }

            .password {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                width: 100%;

                input {
                    width: 100%;
                    // margin-left: 1rem;
                }

                i {

                    // width: 100%;
                    margin-left: 1rem;
                    margin-bottom: 1rem;
                }
            }

            .form-footer {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100%;

                p {
                    // font-size: 1.2rem;
                    margin-bottom: 0.5rem;
                }

                .btn-secondary {
                    padding: 0.5rem;
                    border-radius: 20px;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}