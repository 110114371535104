.color-blue {
    background-color: blue;
}

.scoreboard {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background: rgb(10, 4, 10);
    flex-direction: column;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        border-radius: 20px;
        background-image: url(../../assets/images/scoreboard.jpg);
        width: 96vw;
        // background-size: 96%;
        box-shadow: 0 15px 60px rgba(0, 0, 0, 0.5);

        .heading {
            font-family: 'Hey comic';
            font-size: 4rem;
            color: #000;
        }

        .coloum {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;

            .player {
                background-color: #fff;
                // width: 200px;
                border-radius: 25px;
                // height: 60px;
                // box-shadow: ;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                padding: 0.5rem 1rem;
                margin-bottom: 1rem;

                .rank {
                    font-size: 1.5rem;
                    color: rgb(10, 4, 10);
                    font-family: 'Righteous';
                }

                .avatar {
                    background-color: #230d21;
                    padding: 0.5rem;
                    border-radius: 50%;
                    // aspect-ratio: 1;
                    width: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 50px;

                    img {
                        // aspect-ratio: 1;
                        height: 40px;
                        // width: 50px;
                    }
                }

                .playername {
                    padding: 0px 5px;
                    width: 150px;
                    font-size: 1.5rem;
                    color: rgb(10, 4, 10);
                    font-family: 'Righteous';
                }

                .score {
                    padding: 0px 5px;
                    width: 50px;
                    font-size: 1.5rem;
                    color: rgb(10, 4, 10);
                    font-family: 'Righteous';
                }
            }

        }

        .share {
            color: #fff;
            opacity: 0;

            a {
                color: #fff;
                text-decoration: underline;
            }
        }
    }

    .buttons {
        i {
            width: 60px;
            height: 60px;
            font-size: 2rem;
            background-color: #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin:1rem 0rem 0rem 1rem;
            &:hover {
                color: #fff;
                background-color: #007bff;
            }
        }
        
    }
}