
.loading-area{
    display:grid;
    place-items: center;
    height: 100vh;
}
.loader1 div{
    height:30px;
    width:30px;
    border-radius: 50%;
    transform: scale(0);
    animation:animate111 1.5s ease-in-out infinite;
    display:inline-block;
    margin:.5rem;
    background-color: #f2b900
}
.loader1 div:nth-child(0){
    animation-delay: 0s;
}
.loader1 div:nth-child(1){
    animation-delay: 0.2s;
}
.loader1 div:nth-child(2){
    animation-delay: 0.4s;
}
.loader1 div:nth-child(3){
    animation-delay: 0.6s;
}
.loader1 div:nth-child(4){
    animation-delay: 0.8s;
}
.loader1 div:nth-child(5){
    animation-delay: 1s;
}
.loader1 div:nth-child(6){
    animation-delay: 1.2s;
}

.loader1 div:nth-child(7){
    animation-delay: 1.4s;
}
@keyframes animate111{
    0%,100%{
        transform:scale(0.2);
        background-color:#b900b4
    }
    40%{
        transform:scale(1);
        background-color:#1400c8;
    }
    50%{
        transform:scale(1);
        background-color:#462775;
    }
}