@import '../../global.scss';

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.wrapme {
    .head {
        margin-top: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 2rem;
        border-radius: 25px;

        @include mobile {
            margin-top: 2.5rem;
            padding: 0;
        }

        h1 {
            font-family: 'Hey comic';
            background: linear-gradient(227deg, #1400C8 0%, #00B9FF 100%);
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            text-align: center;

            @include mobile {
                font-size: 1.8rem;
            }
        }

        // .line {
        //     width: 100%;
        //     height: 5px;
        //     background:  linear-gradient(227deg, #B900B4 0%, #F50000 100%);
        // } 

        p {
            max-width: 450px;
            text-align: center;
            margin: 0.5rem;
            font-size: 1.3rem;
            font-weight: 600;
            @include mobile {
                font-size: 1rem;
            }
        }

        .starss {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p {
                margin-bottom: 0.5rem;
                display: flex;

                div {
                    width: 24px;
                    height: 24px;
                    margin-right: 0.3rem;
                }

                .easy {
                    background-color: #00ff00;
                }

                .medium {
                    background-color: #ffff00;
                }
            }
        }
    }
}

body #info {
    padding: 0rem 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;



}

.work-row {
    width: 100%;
    margin: 0 auto;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.worksheet-card {
    // display: inline-block;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 300px;
    padding: 2rem 1rem 2rem 1rem;
    // background: linear-gradient(227deg, #1400C8 0%, #00B9FF 70%);
    position: relative;
    margin: 1rem;
    border-radius: 30px;
    color: #FFF;
    background: #fff;
    background-clip: padding-box;
    /* !importanté */
    border: solid 3px transparent;
    /* !importanté */
    border-radius: 1em;
    .Free {
        position: absolute;
        top: 0;
        right: -20px;
        background: linear-gradient(to right, red, orange);
        padding: 0.5rem 2rem;
        color: #fff;
        font-weight: 600;
        border-radius: 1em;
        rotate : 45deg;
        @include mobile {
            font-size: 0.8rem;
            padding: 0.5rem 1rem;
            rotate: 20deg;
            top: -10px;
        }
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -3px;
        /* !importanté */
        border-radius: inherit;
        /* !importanté */
        background: linear-gradient(to right, red, orange);
    }

    @include mobile {
        padding: 1rem;
        height: auto;
        margin: 0.5rem;
    }

    &:hover {
        h2 {
            color: #414141;
        }
    }

    // &:after {
    //     content: "";
    //     display: block;
    //     width: 0px;
    //     height: 0px;
    //     background-color: skyblue;
    //     top: 0px;
    //     right: 0px;
    //     border-bottom: 20px solid darken(#1400C8, 5%);
    //     border-left: 20px solid darken(#1400C8, 5%);
    //     border-right: 20px solid white;
    //     border-top: 20px solid white;
    //     position: absolute;
    //     filter: drop-shadow(-5px 5px 3px rgba(0, 0, 0, 0.5))
    // }

    // ;

    // &:before {
    //     content: "";
    //     display: block;
    //     width: 0px;
    //     height: 0px;
    //     border-top: 40px solid darken(#00B9FF, 5%);
    //     border-right: 40px solid darken(#00B9FF, 5%);
    //     border-left: 40px solid white;
    //     border-bottom: 40px solid white;
    //     bottom: 0px;
    //     left: 0px;
    //     position: absolute;
    //     filter: drop-shadow(7px -7px 5px rgba(0, 0, 0, 0.5));
    //     margin-right: 10%;
    // }

    // &:nth-of-type(1) {
    //     margin-right: 9%;
    // }

    h2 {
        color: rgb(0, 0, 0);
        font-family: 'Hey comic';
        // margin-bottom: 1rem;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-decoration: underline;

        @include mobile {
            font-size: 1.5rem;
        }

    }

    ;

    p {
        color: rgb(0, 0, 0);
        font-weight: 600;
        font-size: 1.1rem;
        text-align: justify;
        line-height: 140%;
        display: none;
    }
}
