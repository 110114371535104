$primarycolor : #0c0e0c;
$secondarycolor : #eaebea;
$tertiarycolor : #d8323c;
@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,700;1,700&display=swap');
$width: 768px;

@mixin mobile {
    @media (max-width: #{$width}){
        @content
    }
}

.quiz-header {
    top:50px !important;
    @include mobile {
        top:30px !important;
        // margin-top: 130px;
    }
}