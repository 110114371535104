@import '../../global.scss';
.WorksheetSingle {
    display: flex;
    flex-direction: column;
    margin: 8rem;
    min-height: calc(100vh - 8rem);
    position: relative;
    @include mobile {
        margin: 2rem;
        margin-top: 6rem;
        min-height: calc(100vh - 8rem);
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;

        .heading {
            h1 {
                font-family: 'Hey comic';
                text-align: center;
                background: linear-gradient(227deg, #1400C8 0%, #00B9FF 100%);
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;
                box-decoration-break: clone;
                -webkit-box-decoration-break: clone;
                @include mobile {
                    font-size: 1.8rem;
                }
            }

            &::after {
                content: ' ';
                display: inline-block;
                width: 100%;
                height: 3px;
                background: linear-gradient(227deg, #1400C8 0%, #00B9FF 100%);
            }
        }

        .indications {
            display: flex;
            flex-direction: coloum;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 1rem;  

            .indication {
                background-color: #00b712;
                background-image: linear-gradient(315deg, #00b712 0%, #5aff15 74%);
                margin-bottom: 0.5rem;
                height: 50px;
                width: 300px;
                display: flex;
                border-radius: 15px;
                align-items: center;
                padding: 0rem 1rem;
                justify-content: center;
                color: black;
                font-family: "Righteous", cursive;
            }
            .med {
                background-color: #fbb034;
                background-image: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%);
            }
            .blue {
                background-color: #00b9ff;
                background-image: linear-gradient(227deg, #1400C8 0%, #00B9FF 100%);
                color: #fff;
                width: 200px;
            }
        }
        .indication-bulb {
            
            color: black;
            margin-right: 1rem;
            font-family: "Righteous", cursive;
            text-decoration: underline;
        }

        .subhead {
            h2 {
                font-family: 'Hey comic';
                font-size: 3rem;
            }
        }

        .desc {
            font-size: 1.4rem;
            max-width: 600px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-family: "Righteous", cursive;
        }
    }

    .worksheet-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        background: linear-gradient(130deg, rgb(255, 216, 233) 0%, rgb(225, 250, 255) 100%);
        border-radius: 75px;

        .link-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            margin: 2rem;

            h3 {
                font-size: 2.5rem;
                text-align: center;
            }

            .worksheet-topic {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 1rem;

                h3 {
                    margin-bottom: 5%;
                }

                .links {
                    background-color: #00b712;
                    background-image: linear-gradient(315deg, #00b712 0%, #5aff15 74%);
                    margin-bottom: 0.5rem;
                    // height: 50px;
                    width: 350px;
                    display: flex;
                    border-radius: 15px;
                    align-items: center;
                    padding: 0rem 1rem;
                    // justify-content: space-between;
                    color: black;
                    font-family: "Righteous";
                    padding: 10px;
                    .view {
                        flex: 9;
                    }
                    .download {
                        flex: 1;
                    }
                    .bulb {
                        flex: 2;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    a {
                        color: rgb(0, 0, 0);
                        font-size: 1.3rem;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .med {
                    background-image: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%);
                }

                .mate {
                    background: linear-gradient(227deg, #1400C8 0%, #00B9FF 100%);
                    a {
                        color: antiquewhite;
                        font-size: 1.3rem;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}