@import 'node_modules/react-modal-video/scss/modal-video.scss';
#root > div > section.hero-area > div.container > div > div:nth-child(1) > div > ul > li:nth-child(1) > a > div::before {
    animation: glowing 30s linear infinite;
    
}
.slide {
    animation: moveToRight 1s ease-in-out;
    animation-delay: 3010ms;
}
.signupbutton {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #000;
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
    background: #fff;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    transition: scale .3s ease-in-out;
    &:before {
        content: '';
        background: linear-gradient(90deg, rgba(245,0,0,1) 0%, rgba(20,0,200,1) 20%, rgba(255,213,33,1) 40%, rgba(137,33,107,1) 60%, rgba(0,255,86,1) 100%);
        position: absolute;
        top: -2px;
        left:-2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(10px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing 10s linear infinite;
        opacity: 1;
        transition: opacity .3s ease-in-out;
        border-radius: 10px;
    }

    &:after {
        z-index: -1;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fff;
        left: 0;
        top: 0;
        border-radius: 10px;
    }

    &:active {
        scale: 0.95;
        &:before {
            opacity: 0;
        }
    }
    i {
        margin-right: 10px;
    }
}
@keyframes glowing {
    0% { background-position: 0 0; }
    100% { background-position: 400% 0; }
}
@keyframes moveToRight {
    0% {
        transform: translateX(-500px);
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}