.singscore {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(227deg,#13cfb8,#462775 70%);
    .box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 950px;
    }

    .single-box {
        width: 300px;
        background: #fff;
        margin-right: 20px;
        padding: 25px;
        text-align: center;
        box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
        position: relative;
        border-radius: 20px;

        &:last-child {
            margin-right: 0;
        }

        .price {
            font-weight: 600;
            font-style: 45px;
        }

        h2 {
            margin-top: 2px;
            margin-bottom: 15px;
        }

        p {
            line-height: 1.9;
            margin-top: 20px;
        }

        a {
            background: #f57e20;
            color: #fff;
            text-decoration: none;
            border-radius: 50px;
            padding: 10px 20px;
            display: inline-block;
            margin: 30px 10px 0 10px;
        }
        .img {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -50px;
            left: 50%;
            transform: translateX(-50%);
            i {
                font-size: 5rem;
                // text-shadow: -2px 2px 4px rgba(0, 0, 0, 0.5),
                // 2px -2px 0 rgba(255, 255, 255, 0.9);
                -webkit-background-clip: text;
                
                
            }
            .true {
                color: transparent;
                -webkit-box-decoration-break: clone;
                background-color: #fbb034;
                background-image: linear-gradient(35deg, #fbb034 0%, #ffdd00 74%);
                background-size: 200% 200%;
                animation :compGradient 5s ease infinite;
            }
            
        }
    }

    

    // .img {
    //     position: absolute;
    //     top: -130px;
    //     left: 15%;
    // }

    @media (max-width: 991px) {
        .box {
            width: 100px;
        }
    }

    @media (max-width: 767px) {
        .box {
            width: 300px;
            flex-direction: column;
        }

        .single-box {
            margin-right: 0;
            width: 300px;
            margin-bottom: 110px;
        }
    } 
}