.launch {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(130deg, #ffd8e9 0%, #e1faff 100%);
    h1 {
        font-size: 3rem;
        padding: 2rem;
        background-color: #fff;
        text-align: center;
        // drop shadow
        -webkit-box-shadow: 6px 10px 26px -4px rgba(0,0,0,0.75);
        -moz-box-shadow: 6px 10px 26px -4px rgba(0,0,0,0.75);
        box-shadow: 6px 10px 26px -4px rgba(0,0,0,0.75);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 2rem;
        margin-bottom: 1rem;
        span {
            font-size: 5rem;
            margin-bottom: 1rem;
        }
    }
}