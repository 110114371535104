@import '../../global.scss';

$shadow: rgba(255, 255, 255, 0.7);
$color1: rgba(255, 0, 0, 0.7);
$color2: rgba(255, 167, 0, 0.7);
$color3: rgba(255, 244, 0, 0.7);
$color4: rgba(163, 255, 0, 0.7);
$color5: rgba(44, 186, 0, 0.7);
$color1a: rgb(255, 0, 0);
$color2a: rgb(255, 167, 0);
$color3a: rgb(255, 244, 0);
$color4a: rgb(163, 255, 0);
$color5a: rgb(44, 186, 0);

.CompPlaying {
    // color variables
    $green: rgb(44, 186, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    min-height: 100vh;
    background: linear-gradient(35deg,rgba(255, 77, 0, 0.75),rgba(236, 159, 5, 0.7) 74%) ,url('../../assets/images/background1.png'), ;
    background-color: #ffaa00;

    background-size: 100%;
    overflow: hidden;
    .timer {
        width: 120px;
        height: 50px;
        background: $green;
        border-radius: 2rem;
        position: absolute;
        top: 20px;
        right: 10px;
        display: flex;
        font-size: 2rem;
        color: #fff;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;
        // animation: animate-pulse 1s linear infinite;
        color: #fff;
        transition: all 0.5s ease-in-out;
    }
    
    .less10,
    .less9 {
        animation: animate-pulse 1s linear infinite;
        background-color: $color5a;
        $shadow: rgba(44, 186, 0, 0.7);
        color: #000;
    }
    
    .less8,
    .less7 {
        animation: animate-pulse 1s linear infinite;
        background-color: $color4a;
        $shadow: rgba(163, 255, 0, 0.7);
        color: #000;

    }

    .less6,
    .less5 {
        background-color: $color3a;
        animation: animate-pulse 1s linear infinite;
        $shadow: rgba(255, 244, 0, 0.7);
        color: #000;

    }

    .less4,
    .less3 {
        background-color: $color2a;
        animation: animate-pulse 1s linear infinite;
        $shadow: rgba(255, 167, 0, 0.7);
        color: #000;

    }

    .less2,
    .less1,
    .less0 {
        background-color: $color1a;
        animation: animate-pulse 1s linear infinite;
        $shadow: rgba(255, 0, 0, 0.7);
        color: #000;

    }

    .cont {
        z-index: 1;
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
        min-height: 100vh;
        // align-items: center;
        justify-content: center;
        
        .head {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            .option {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                background: rgb(255, 255, 255);
                background-position: 82%;
                border: #000 solid 3px;
                background-size: 200%;
                border-radius: 1000000000px;
                transition: background-position 0.5s ease;
                padding: 5px 20px;
                width: 400px;
                h3 {
                    font-size: 1.5rem;
                    font-weight: 600;
                    color: #000;
                }
            }
            
            .small {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                background: rgb(255, 255, 255);
                background-position: 82%;
                border: #000 solid 3px;
                background-size: 200%;
                border-radius: 1000000000px;
                transition: background-position 0.5s ease;
                padding: 5px 20px;
                position: relative;
                top: 80px;
                // pointer on hover
                cursor: pointer;
                h3 {
                    font-size: 1.5rem;
                    font-weight: 600;
                    color: #000;
                }
            }
            .option:hover {
                cursor: pointer;
            }
            .answerd {
                
                background-position: 0%;
            }
        }
        .clown {
            flex-direction: row-reverse;
        }
        .question-wrapper {
            height: 350px;
            display: flex;
            // top: 30vh;
            // position: absolute;
            left: 0;
            transition: all 0.5s ease-out;
            // transition-delay: 2s;
            margin-bottom: 2rem;
            .question {
                min-width: 100vw;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 15px;
                .number {
                    font-size: 2rem;
                    font-weight: 600;
                    color: #000;
                    background-color: #fff;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 5px 10px;
                    border-radius: 50%;
                    position: relative;
                    border: 3px solid #000;
                    top: 10px;
                    z-index: 1;
                }   
                .bigg {
                    font-size: 4rem;
                    width: 100px;
                    height: 100px;
                }
                h2 {
                    background: #fff;
                    border-radius: 20px;
                    border: 3px solid #000;
                    padding: 10px;
                    text-align: center;
                    font-size: 1.5rem;
                }
            }
            .answers {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 10px;
                .option {
                    display: flex;
                    align-items: center;
                    // justify-content: center;
                    gap: 10px;
                    background: rgb(226,194,58);
                    background: linear-gradient(90deg, #ffbf00 0%, #ffbf00 48%, rgba(255,255,255,1) 52%, rgba(255,255,255,1) 100%);
                    background-position: 82%;
                    border: #000 solid 3px;
                    background-size: 200%;
                    border-radius: 1000000000px;
                    transition: background-position 0.5s ease;
                    padding: 5px 20px;
                    width: 300px;

                    .abcd {
                        font-size: 1.2rem;
                        color: #fff;
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                    h3 {
                        font-size: 1.5rem;
                        font-weight: 600;
                        color: #000;
                    }
                }
                .option:hover {
                    cursor: pointer;
                }
                .answerd {
                    
                    background-position: 0%;
                }
            }
        }
    }
}

@keyframes animate-pulse {
    0% {
        box-shadow: 0 0 0 0 $shadow, 0 0 0 0 $shadow;
    }

    40% {
        box-shadow: 0 0 0 50px rgba(255, 255, 255, 0), 0 0 0 0 $shadow;
    }

    80% {
        box-shadow: 0 0 0 50px rgba(255, 109, 74, 0.0), 0 0 0 30px rgba(255, 109, 74, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 109, 74, 0.0), 0 0 0 30px rgba(255, 109, 74, 0);
    }

}