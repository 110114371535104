@import '../../global.scss';

.waiting {
    position: relative;
    width: 100%;
    padding-top: 5rem;
    display: flex;
    align-items: center;
    flex-direction: column; 
    gap: 40px;
    flex-wrap: wrap;
    min-height: 100vh;
    .timer-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #fbb016;
        border-radius: 20px;
        padding: 20px;
        box-shadow: inset 0 0 0 1px #fbb016, 0 0 0 1px #fbb016;
        h1 {
            color: #fff;
        }
        h3 {
            color: #fff;
        }
    }
    .rules-wrapper {
        display: flex;
        width: 90%;
        max-width: 500px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #fbb016;
        border-radius: 20px;
        padding: 20px;
        box-shadow: inset 0 0 0 1px #fbb016, 0 0 0 1px #fbb016;
        h1 {
            color: #fff;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                color: #fff;
                font-size: 1.2rem;
            }
        }
    }
}