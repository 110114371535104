.sideMenu {
    ul {
        li {
            a {
                color: rgb(0, 0, 0);
                font-size: 1.5rem;
                font-weight: 600;
                padding: 0.5rem 0;
                display: block;
                text-decoration: none;
                transition: all 0.3s ease-in-out;
                &:hover {
                    color: #333333;
                }
            }
        }
    }
}