@import "../../global.scss";

.compPapers {
    display: flex;
    margin-top: 100px;
    min-height: 100vh;
    align-items: center;
    gap: 20px;
    // linear gradient
    background: linear-gradient(227deg, #13cfb8, #462775 70%);
    // justify-content: center;
    flex-direction: column;
    .Heading {
        h1 {
        color: #fff;
        }
    }
    .cardsla {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        .cardla {
            margin-bottom: 20px;
            width: 90%;
            max-width: 350px;
            background: #fff;
            padding: 20px 10px;
            border: #f57e20 5px solid;
            border-radius: 20px;

            .title {
                font-size: 1.5rem;
                font-weight: 600;
                text-align: center;
                margin-bottom: 20px;
            }
            .standard {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                .paper {
                    padding: 3px 5px;
                    min-width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                    background: #f57e20;
                    h4 {
                        color: #fff;

                    }
                }
            }
        }        
    }
    
}