@import "./../../global.scss";
.VocabGames {
    overflow: hidden;
    min-height: 100vh;
    position: relative;
    background-color: #ff4e00;
    background-image: linear-gradient(227deg, #ff4e00 0%, #ec9f05 74%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .wave {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background: url('../../assets/images/wave.png');
        background-size: 1000px 100px;
    }

    .wave1 {
        animation: wave 30s linear infinite;
        z-index: 1000;
        opacity: 1;
        animation-delay: 0s;
        bottom: 0;
    }

    .wave2 {
        animation: wave2 15s linear infinite;
        z-index: 999;
        opacity: 0.5;
        animation-delay: -5s;
        bottom: 10px;
    }

    .wave3 {
        animation: wave1 10s linear infinite;
        z-index: 998;
        opacity: 0.2;
        animation-delay: -2s;
        bottom: 15px;
    }

    .wave4 {
        animation: wave2 25s linear infinite;
        z-index: 997;
        opacity: 0.7;
        animation-delay: -5s;
        bottom: 20px;
    }

    .sun {
        position: absolute;
        display: inline-block;

        bottom: -150px;
        width: 400px;
        height: 400px;
        background: orange;
        box-shadow: 0 0 10px orange,
            0 0 60px orange,
            0 0 200px yellow,
            inset 0 0 80px yellow;
        border-radius: 50%;
    }

    .head {
        font-size: 3rem;
        font-weight: 600;
        font-family: 'Hey comic';
        color: #fff;
        // height: px;
    }
    .gamecard {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: 10rem;
        @include mobile {
            margin-top: 2.5rem;
        }
        h2 {
            font-size: 3rem;
            font-weight: 600;
            color: #fff;
            // margin-bottom: 2rem;
        }
        .htp {
            p {
                font-weight: 600;
                font-size: 1.5rem;
                text-decoration: underline;
                color: #fff;
                i {
                    margin-right: 0.5rem;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .games {
            font-weight: 400;
            background-color: rgba(255, 255, 255, 0.06);
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 15px;
            padding: 1rem;
            backdrop-filter: blur(30px);
            box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
            margin: 1rem;
            width: 300px;
            // &::after {
            //     height: 4px;
            //     display: inline-block;
            //     width: 100%;
            //     background-color: #fff;
            // }
            @include mobile {
                margin: 2rem;
            }
            h1 {
                color: #fff;
                margin-bottom: 2px;
                font-size: 1.5rem;

            }
            p{
                color: #fff;
                font-family: 'Righteous';
                font-size: 1.3rem;
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.2);
            }
        }
    }
}

@keyframes wave {
    0% {
        background-position-x: 0px;
    }

    100% {
        background-position-x: 1000px;
    }
}

@keyframes wave2 {
    0% {
        background-position-x: 0px;
    }

    100% {
        background-position-x: -1000px;
    }
}