@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

.mainnn {

  .container {
    width: 380px;
    padding: 0;
    margin: 10% auto 0 auto;
    -ms-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
    position: releative;
    overflow: hidden;
  }

  .logo-container {
    margin: 0;
    padding: 0;
    width: 100%;
    display: block;
    position: relative;
    height: 260px;
    overflow: hidden;
  }

  .header {
    position: absolute;
    margin-top: 40px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('https://cnet3.cbsistatic.com/img/Yt768C55hXNi2eGSB9qOv-e7SQg=/2011/03/16/c7675aa8-fdba-11e2-8c7c-d4ae52e62bcc/Chrome-logo-2011-03-16.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .message {
    margin: 0;
    padding: 20px 0 40px 0;
    text-align: center;
  }

  h1 {
    line-height: 1.25;
    font-family: 'Montserrat', sans-serif;
  }

  p {
    margin-top: 20px;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
  }
}