@import '../../global.scss';
:root {
  --current-color: #ffbf00;
  --wrong-color: linear-gradient(147deg, #990000 0%, #ff0000 74%);
  --right-color: linear-gradient(315deg, #00b712 0%, #5aff15 74%);
  --not-attended-color: #fff;
}
* {
  box-sizing: border-box;
}

.area {
  // position: relative;
  background: #4e54c8;
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
  height: 100vh;
  // z-index: -5;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
  z-index: 1;

}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {

  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0.5;
    border-radius: 50%;
  }

}

.playing {
  text-align: center;
  width: 100vw;
  height: 100vh;
  // .timer {
  //   position: absolute;
  //   top: 10px;
  //   left: 10px;
  //   padding: 0rem 2rem;
  //   font-size: 2rem;
  //   border-radius: 1000px;
  //   z-index: 1;
  //   background-color: white;
  //   color: black;
  //   font-weight: 600;
  // }
  .vol {
    position: fixed;
    top: 10px;
    right: 30px;
    z-index: 100;
    color: #fff;
    font-size: 3rem;
    @include mobile {
      font-size: 1.5rem;
    }
  }
  .round-time-bar {
    overflow: hidden;
  }

  .round-time-bar div {
    height: 5px;
    animation: roundtime calc(450 * 1s) steps(450) forwards;
    transform-origin: left center;
    background: linear-gradient(to bottom, red, #900);

    img {
      width: 40px;
      height: 40px;
      z-index: 10;
      position: relative;
      top: 20px;
    }
  }

  .round-time-bar[data-style="smooth"] div {
    animation: roundtime calc(450 * 1s) linear forwards;
  }



  @keyframes roundtime {
    to {
      /* More performant than `width` */
      transform: scaleX(0);
    }
  }
}

.text-white {
  color: #fcfcfc;
}

.rounded-pill {
  border-radius: 50rem
}

.d-inline-block {
  display: inline-block;
}

.text-center {
  text-align: center;
}

.wrapper {
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  // background-image: -webkit-gradient(linear, left top, right top, from(rgba(38, 142, 255, 0.8)), to(rgba(91, 117, 255, 0.7))), url("../images/bg_0.png");
  // background-image: linear-gradient(to right, rgba(38, 142, 255, 0.8), rgba(91, 117, 255, 0.7)), url("../../assets/images/bg_0.png");
  background: #4e54c8;
  background-repeat: no-repeat;
}

.wrapper .container-fluid {
  max-width: 1600px;
  margin: auto;
  position: relative;
}

.step_bar_content h5 {
  font-size: 1.25rem;
  font-weight: 600;
}

.step {
  height: 2.0625rem;
  width: 2.0625rem;
  font-size: 1.075rem;
  line-height: 2rem;
  text-align: center;
  font-weight: 400;
  font-family: 'Righteous';
  background: var(--not-attended-color);
  position: relative;
  margin: 0.1em;
  color: #000a38;
}

.step:not(:last-child) {
  margin-right: 1.875rem;
}

.step:after {
  content: "";
  top: 50%;
  left: 40px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1.2rem;
  height: 0.1rem;
  position: absolute;
  background: var(--not-attended-color);
}

.step:last-child {
  margin-right: 30px;
}

.step:last-child:after {
  display: none;
}

.step.active {
  background: var(--current-color);
  opacity: 1;
}

.step.active::after {
  background: var(--current-color);
}

.step.correct {
  background: var(--right-color);
  opacity: 1;
  color: white;
}

.step.correct::after {
  background: var(--right-color);
}

.step.incorrect {
  background: var(--wrong-color);
  opacity: 1;
  color: white;
}

.step.incorrect::after {
  background: var(--wrong-color);
}

.step.finish {
  background: var(--current-color);
}

.step.finish::after {
  background: var(--current-color);
}

.form_btn .f_btn {
  color: #ffffff;
  font-size: 1.3rem;
  font-weight: 800;
  padding: 0.75rem 2rem;
  margin: 5px;
  outline: none;
  border: none;
  z-index: 100;
}

.form_btn .f_btn span {
  margin: 0.3rem;
}

.form_btn .disable {
  color: #ffffff;
  background: #172126;
  margin-right: 0.625rem;
}

.form_btn .disable:hover {
  color: #000000;
  background: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form_btn .active {
  color: #ffffff;
  background: #172126;
  font-family: 'Righteous';
  font-weight: 200;

}

.form_btn .active:hover {
  color: #000000;
  background: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.nop {
  display: none;
}

.form_title h1 {
  margin: 2rem 0rem;
  font-size: 2.5rem;
  font-weight: 600;
}

.form_title_2 h1 {
  font-size: 2rem;
  margin: 2rem 0rem;
  font-weight: 600;

  .ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    .li {
      padding: 1px 4px;
      z-index: 100;
    }
    .ddc {
      background: linear-gradient(227deg,rgba(90, 255, 21, 1),rgba(0, 183, 18, 1));
      border-radius: 10px;
    }
    .moving {
      color: var(--not-attended-color);
    }
  }
}

.answerBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


  .dropBox {
    width: 25rem;
    height: 3rem;
    padding: 0.2rem 2rem;
    border: solid 1px var(--not-attended-color);
    margin-bottom: 1rem;
    background: linear-gradient(90deg, rgba(90, 255, 21, 1) 0%, rgba(0, 183, 18, 1) 33%, rgba(255, 255, 255, 1) 33%, rgba(255, 255, 255, 1) 66%, rgba(255, 0, 0, 1) 66%, rgba(153, 0, 0, 1) 100%);
    background-size: 310%;
    background-position: -99%;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease 1s;
    font-size: larger;
    font-family: 'Righteous';
    z-index: 100;
  }

  .incorrect {
    background-position: 99%;
    color: white;
  }
  .correct {
    background-position: 0%;
    color: white;

  }

  .over {
    background: linear-gradient(90deg, rgba(90,255,21,1) 0%, rgba(0,183,18,1) 33%, rgba(255,191,0,1) 33%, rgba(255,191,0,1) 66%, rgba(255,0,0,1) 66%, rgba(153,0,0,1) 100%);
    background-position: -99%;
    background-size: 310%;
    
  }
}



.answerBox .options {
  z-index: 100;
  .option {
    width: 25rem;
    padding: 1rem;
    // border: solid 1px var(--not-attended-color);
    // background: rgb(90,255,21);
    background: linear-gradient(90deg, rgba(90, 255, 21, 1) 0%, rgba(0, 183, 18, 1) 33%, rgba(255, 255, 255, 1) 33%, rgba(255, 255, 255, 1) 66%, rgba(255, 0, 0, 1) 66%, rgba(153, 0, 0, 1) 100%);
    background-size: 310%;
    background-position: -99%;
    font-weight: 600;
    font-size: 1.3rem;
    margin-bottom: 1rem;
    // background: white;
    border-radius: 50px;
    z-index: 100;
    transition: all ease 1s;
    // background-repeat: no-repeat;

  }
  .incorrect {
    background-position: 99%;
    color: white;
  }
  .correct {
    background-position: 0%;
    color: white;

  }
}

@media screen and (max-width: 1399.98px) {
  .form_title h1 {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 1199.98px) {
  .form_title h1 {
    font-size: 2rem;
  }

  .form_title_2 h1 {
    font-size: 1.5rem;
  }

  .form_items label {
    padding: 0rem 1rem;
  }

  .form_items span {
    padding: 0.5rem 0.7rem;
  }

  .form_btn .f_btn {
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
  }
}

@media screen and (max-width: 991.98px) {
  .step_bar_content {
    text-align: center;
    padding-left: 0 !important;
  }

  .progress_bar {
    text-align: center;
    padding-left: 0 !important;
    display: block !important;
  }

  .form_content {
    padding-top: 3rem;
  }

  .form_title {
    text-align: center;
    padding-left: 0 !important;
  }

  .form_title_2 {
    text-align: center;
    padding-left: 0 !important;
  }

  .form_img img {
    width: 40%;
  }

  .form_items {
    text-align: center;
    padding-top: 2rem;
  }

  .form_btn .f_btn {
    font-size: 1rem;
    padding: 1rem 2rem;
  }
}

@media screen and (max-width: 767.98px) {
  .form_title h1 {
    font-size: 2rem;
  }

  .form_btn .f_btn {
    padding: 0.8rem 1rem;
  }
}

@media screen and (max-width: 575.98px) {
  .form_title h1 {
    font-size: 1.5rem;
    padding: 0.5rem 0rem;
  }

  .form_title_2 h1 {
    font-size: 1.5rem;
    padding: 1rem 0rem;
  }

  .form_btn .f_btn {
    padding: 0.8rem 1rem;
  }

  .answerBox .dropBox {
    width: 20rem;
  }

  .answerBox .options .option {
    width: 20rem;
  }
}

@media screen and (max-width: 379.98px) {
  .step_bar_content h5 {
    font-size: 0.8rem;
    font-weight: 600;
  }

  .answerBox .dropBox {
    width: 20rem;
  }

  .answerBox .options .option {
    width: 20rem;
  }

  .step {
    height: 1.6rem;
    width: 1.6rem;
    font-size: 0.8rem;
    line-height: 1.7rem;
  }

  .step:not(:last-child) {
    margin-right: 1.5rem;
  }

  .step:after {
    width: 1.1rem;
    height: 0.125rem;
    left: 30px;
  }

  .step:last-child {
    margin-right: 24px;
  }

  .form_title {
    padding: 0rem;
  }

  .form_title h1 {
    font-size: 1.5rem;
    margin: 0rem;
  }

  .form_title_2 h1 {
    font-size: 1.8rem;
    margin: 0rem;
  }

  .form_items label {
    padding: 0rem;
  }

  .form_items span {
    padding: 0.5rem 0.7rem;
  }

  .form_items .label-content {
    min-width: 150px;
  }

  .form_btn .f_btn {
    font-size: 1rem;
    padding: 0.8rem;
  }
}