@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import '../../global.scss';

.compbackground {
  background: linear-gradient(132deg, #FC415A, #591BC5, #212335);
  background-size: 400% 400%;
  animation: compGradient 15s ease infinite;
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0px;

  .parent {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Righteous", cursive;
    min-height: 100vh;
    padding-top: 5rem;
    flex-direction: column;
    // background-color: #b61924;

    .heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .trophy {
        width: 200px;
        @include mobile {
          width: 100px;
      }
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        h1 {
          font-family: 'Hey comic';
          // background: linear-gradient(to left, #da4453, #89216b);
          // background-clip: text;
          // -webkit-background-clip: text;
          color: white;
          box-decoration-break: clone;
          -webkit-box-decoration-break: clone;
          font-size: 5rem;
          z-index: 10;
        text-align: center;
        position: relative;
          @include mobile {
            font-size: 2.5rem;
        }

          &::after {
            content: "";
            height: 3px;
            width: 100%;
            display: inline-block;
            background-color: #fff;
            position: absolute;
            bottom: -10px;
            left: 0;
          }
        }

        i {
          // color: #000;
          // background-color: #fff;
          // display: none;
          // background-color: #ffc000;
          // background-image: linear-gradient(315deg, #d09d1f 0%, #c6920d 74%);
          // background-clip: text;
          -webkit-background-clip: text;
          font-size: 7rem;
          color: #fff;

        }
      }

      p {
        color: #fff;
        padding: 8px;
        text-align: center;
        max-width: 500px;
      }

      @media screen and (max-width: 768px) {
        h1 {
          font-size: 3rem;

        }
      }
    }

    .practice {
      min-width: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      background: linear-gradient(227deg, #1400C8 0%, #00B9FF 100%);
      margin: 1em 1em 0 1em;
      box-shadow: 0 15px 60px rgba(0, 0, 0, 0.5);
      border-radius: 15px;
      z-index: 10;
      padding: 1rem;
      transition: all 0.5s ease;
      transform: translateY(-0.1rem);
      @include mobile {
        margin-top: 2em;
        min-width: 250px;
    }
      &:hover {
        transform: translateY(-0.6rem);
      }

      h2 {
        font-family: 'Hey comic';
        font-size: 3em;
        color: #fff !important;
        @include mobile {
          font-size: 1.5rem;
      }
      }

      p{
        font-family: 'Hey comic';
        font-size: 1.5em;
        color: #fff !important;
      }
    }

    .card-container {
      max-width: 100vw;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 35px;
      margin: 0 auto;
      padding: 40px 0;

      .card {
        position: relative;
        width: 300px;
        height: 400px;
        margin: 0 auto;
        background: rgb(0, 0, 0);
        border-radius: 15px;
        box-shadow: 0 15px 60px rgba(0, 0, 0, 0.5);

        @media screen and (max-width: 768px) {
          height: 270px;
        }

        .face {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;

          &.face2 {
            overflow: hidden;
            z-index: 0;
            padding: 10.55px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            p {
              text-align: center;
            }

            &::before {
              content: "";
              position: absolute;
              z-index: -2;
              left: -50%;
              top: -50%;
              width: 200%;
              height: 200%;
              background-color: #399953;
              background-repeat: no-repeat;
              background-size: 50% 50%, 50% 50%;
              background-position: 0 0, 100% 0, 100% 100%, 0 100%;
              background-image: linear-gradient(#399953, #399953),
                linear-gradient(#fbb300, #fbb300),
                linear-gradient(#d53e33, #d53e33),
                linear-gradient(#377af5, #377af5);
              animation: rotate 4s linear infinite;
            }

            &::after {
              content: "";
              position: absolute;
              z-index: -1;
              left: 6px;
              top: 6px;
              width: calc(100% - 12px);
              height: calc(100% - 12px);
              background: white;
              border-radius: 5px;
            }
          }

          &.face1 {
            box-sizing: border-box;
            padding: 0.5rem;

            .content {
              display: flex;
              align-items: center;
              justify-content: center;

              @media screen and (max-width: 768px) {
                align-items: flex-start;
              }
            }

            h2 {
              margin: 1rem;
              padding: 0;
              text-align: center;

            }

            p {
              color: white;
              margin: 1rem;
              text-align: center;
            }

            .java {
              background-color: #fffc00;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            .python {
              background-color: #00fffc;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }

          &.face2 {
            transition: 0.5s;

            h2 {
              font-family: 'Hey comic';
              margin: 0;
              padding: 0;
              font-size: 2em;
              color: #000000;
              transition: 0.5s;
              text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
              z-index: 10;
            }
          }
        }
      }

      .card:hover .face.face2 {
        height: 60px;

        &::before {
          position: absolute;
          z-index: -2;
          left: -50%;
          top: -225%;
          width: 200%;
          height: 600%;
        }

        p {
          display: none;
        }

        h2 {
          font-size: 2em;
        }
      }

      .card:nth-child(1) .face.face2 {
        background-color: #fff;
        border-radius: 10px;
      }

      .card:nth-child(2) .face.face2 {
        background-color: #fff;
        border-radius: 15px;
      }
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }

  .main {
    display: flex;
    flex-direction: column;

    // transform: rotatex(10deg);

    .active {

      animation: rotateAngle 12s linear infinite;
    }

    align-items: center;
    justify-content: flex-start;

    input {
      height: 3rem;
      border-radius: 15px;
      margin: 0.5rem;
      padding: 0.5rem;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
    }
  }

  .comp-button {
    transform: translateY(-2.6rem);
    display: block;
    position: relative;
    margin: 0.5em 0;
    padding: 0.8em 2.2em;
    cursor: pointer;

    background: #ffffff;
    border: none;
    border-radius: 0.4em;

    text-transform: uppercase;
    font-size: 1.4em;
    font-family: "Work Sans", sans-serif;
    font-weight: 500;
    letter-spacing: 0.04em;

    mix-blend-mode: color-dodge;
    perspective: 500px;
    transform-style: preserve-3d;

    &:before,
    &:after {
      --z: 0px;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      opacity: 0;
      mix-blend-mode: inherit;
      border-radius: inherit;
      transform-style: preserve-3d;
      transform: translate3d(calc(var(--z) * 0px),
          calc(var(--z) * 0px),
          calc(var(--z) * 0px));
    }

    span {
      mix-blend-mode: none;
      display: block;
      font-family: 'Hey comic';
    }

    &:after {
      background-color: #5d00ff;
    }

    &:before {
      background-color: #ff1731;
    }

    &:hover {
      background-color: #fff65b;
      // transition: background 0.3s 0.1s;
    }

    &:hover:before {
      --z: 0.04;
      animation: translateWobble 2.2s ease forwards;
    }

    &:hover:after {
      --z: -0.06;
      animation: translateWobble 2.2s ease forwards;
    }
  }

  @keyframes rotateAngle {
    0% {
      transform:  rotateX(0deg);
      animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1);
    }

    25% {
      transform:  rotateX(0deg);
    }

    50% {
      transform:  rotateX(0deg);
      animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1);
    }

    75% {
      transform:  rotateX(0deg);
    }

    100% {
      transform:  rotateX(0deg);
    }
  }

  @keyframes translateWobble {
    0% {
      opacity: 0;
      transform: translate3d(calc(var(--z) * 0px),
          calc(var(--z) * 0px),
          calc(var(--z) * 0px));
    }

    16% {
      transform: translate3d(calc(var(--z) * 160px),
          calc(var(--z) * 160px),
          calc(var(--z) * 160px));
    }

    28% {
      opacity: 1;
      transform: translate3d(calc(var(--z) * 70px),
          calc(var(--z) * 70px),
          calc(var(--z) * 70px));
    }

    44% {
      transform: translate3d(calc(var(--z) * 130px),
          calc(var(--z) * 130px),
          calc(var(--z) * 130px));
    }

    59% {
      transform: translate3d(calc(var(--z) * 85px),
          calc(var(--z) * 85px),
          calc(var(--z) * 85px));
    }

    73% {
      transform: translate3d(calc(var(--z) * 110px),
          calc(var(--z) * 110px),
          calc(var(--z) * 110px));
    }

    88% {
      opacity: 1;
      transform: translate3d(calc(var(--z) * 90px),
          calc(var(--z) * 90px),
          calc(var(--z) * 90px));
    }

    100% {
      opacity: 1;
      transform: translate3d(calc(var(--z) * 100px),
          calc(var(--z) * 100px),
          calc(var(--z) * 100px));
    }
  }

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    // right: 0;
    right: 00px;
    /* transform: rotate(35deg); */
  }
}

.compcube {
  position: absolute;
  top: 100vh;
  left: 70vw;
  width: 10px;
  height: 10px;
  border: solid 1px #D7D4E4;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  animation: compcube 12s ease-in forwards infinite;
}

.compcube:nth-child(2n) {
  border-color: #FFF;
}

.compcube:nth-child(2) {
  animation-delay: -10s;
  left: 25vw;
  top: 40vh;
}

.compcube:nth-child(3) {
  animation-delay: -8s;
  left: 75vw;
  top: 50vh;
}

.compcube:nth-child(4) {
  animation-delay: -6s;
  left: 90vw;
  top: 10vh;
}

.compcube:nth-child(5) {
  animation-delay: -4s;
  left: 10vw;
  top: 85vh;
}

.compcube:nth-child(6) {
  animation-delay: -2s;
  left: 50vw;
  top: 10vh;
}

/* Animate Background*/
@keyframes compGradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes compcube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
    border-radius: 0%;
  }

  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
    border-radius: 50%
  }
}