.dropit {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 100vw;
    background-image: linear-gradient(227deg, #ff4e00 0%, #ec9f05 74%);

    .title {
        color: #fff;
    }

    p {
        color: #fff;
    }

    .butts {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        // margin: 1rem;
        a {

            .choice {
                width: 50px;
                height: 50px;
                font-weight: 600;
                background-color: rgba(255, 255, 255, 0.06);
                border: 1px solid rgba(255, 255, 255, 0.1);
                border-radius: 15px;
                backdrop-filter: blur(30px);
                box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 1rem;
                margin-top: 1rem;
                color: #fff;

                &:hover {
                    background-color: rgba(255, 255, 255, 0.2);
                }
            }
        }
    }

}