$accent-color: #00a800;
$red-color: #ed1c24;
@import '../../global.scss';

* {
	box-sizing: border-box;
}

.topic {
	margin-top: 15rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	font-family: 'Montserrat', sans-serif;

	.head {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 2rem;
		border-radius: 25px;
		background: linear-gradient(130deg, rgb(255, 216, 233) 0%, rgb(225, 250, 255) 100%);

		h1 {
			font-family: 'Hey comic';
			background: linear-gradient(227deg, #B900B4 0%, #F50000 100%);
			background-clip: text;
			-webkit-background-clip: text;
			color: transparent;
		}

		p {
			font-family: 'Righteous';
			max-width: 450px;
			text-align: center;
			margin: 0.5rem;
			font-size: 1.3rem;
		}

		.starss {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			p {
				margin-bottom: 0.5rem;
				display: flex;

				div {
					width: 24px;
					height: 24px;
					aspect-ratio: 1/1;
					// margin-right: 0.3rem;
					border-radius: 50%;
				}

				.easy {
					background-color: $accent-color;
				}

				.medium {
					background-color: $red-color;
				}
			}
		}
	}
}

.topic-wrapper {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.card {
	max-width: 420px;
	width: 80%;
	height: 340px;
	margin: 1em;
	perspective: 1500px;
	
	.Free {
		position: absolute;
		z-index: 1;
		top: 0;
		right: -20px;
		font-size: 1.5rem;
		// width: 100%;
		// height: 100%;
		background: linear-gradient(130deg, rgb(255, 216, 233) 0%, rgb(225, 250, 255) 100%);
		padding: 0.2rem 1rem;
		rotate: 45deg;
		font-family: 'Righteous';
		border-radius: 1000px;
		color: #000;
		@include mobile {
			font-size: 1.5rem;
			rotate: 00deg;
			top: -0.85rem;
		}
	}

	.content {
		position: relative;
		width: 100%;
		height: 100%;
		transform-style: preserve-3d;
		transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1);
	}
}

.more {
	display: none;

	&:checked~.content {
		transform: rotateY(180deg);
	}
}

.front,
.back {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	transform-style: preserve-3d;
	border-radius: 6px;

	.inner {
		height: 100%;
		display: grid;
		padding: 1.5em;
		transform: translateZ(80px) scale(0.94);
	}
}

.front {
	background-color: #fff;
	background-size: cover;
	background-position: center center;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		border-radius: 6px;
		backface-visibility: hidden;
		background-size: 300%;
		background: linear-gradient(to left, #da4453, #89216b);
	}

	.inner {
		grid-template-rows: 5fr 1fr 1fr 2fr 1fr;
		justify-items: center;
	}

	h3 {
		grid-row: 2;
		font-family: 'Hey comic';
		margin-bottom: 0.3em;
		text-transform: uppercase;
		letter-spacing: 3px;
		color: #fff;
		font-weight: 500;
		text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
	}

	.rating {
		grid-row: 3;
		color: rgba(255, 255, 255, 0.8);
		font-size: 14px;
		display: flex;
		flex-flow: row nowrap;
		color: $accent-color;

		.medium {
			color: rgb(255, 255, 0);
		}

		.hard {
			color: red;
		}

		i {
			margin: 0 1px;
		}
	}
}

.back {
	transform: rotateY(180deg);
	background-color: #fff;
	border: 2px solid rgb(240, 240, 240);

	.inner {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.title {
			color: #355cc9;
			font-size: 2rem;
		}

		.medium {
			color: $red-color;
		}

		.hard {
			color: #ff0000;
		}

		.cover {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			flex-direction: column;
			flex-wrap: wrap;
			div {
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				h3 {
					position: relative;
					// top: 10px;
					// right: 10px;
				}
				div {
					display: flex;
					flex-direction: row;
				}
			}
		}
	}
	.hide {
		display: none!important;
	}
	.info {
		position: relative;
		margin-right: 5px;
		display: flex;
		align-items: center;
		color: $accent-color;
		flex-wrap: wrap;
		// &:not(:first-of-type):before {
		// 	content: '';
		// 	position: absolute;
		// 	left: -0.9em;
		// 	height: 18px;
		// 	width: 1px;
		// 	background-color: #ccc;
		// }
		span {
			font-size: 2em;
			font-weight: 700;
		}

		i {
			&:before {
				background: linear-gradient(40deg, $accent-color, rgb(67, 138, 243));

				-webkit-text-fill-color: transparent;
				-webkit-background-clip: text;
			}

			font-size: 1.2em;
		}

		.icon {
			margin-left: 0.3em;

			span {
				display: block;
				margin-top: -0.25em;
				font-size: 0.8em;
				font-weight: 600;
				white-space: nowrap;
			}
		}
	}

	.description {
		grid-row: 5;
		grid-column: 1/-1;
		font-size: 0.86em;
		border-radius: 5px;
		font-weight: 600;
		line-height: 1.4em;
		overflow: auto;
		color: $accent-color;
		padding-right: 10px;
	}

	.location,
	.price {
		font-weight: 600;
		color: $accent-color;
		grid-row: 1;
		font-size: 0.86em;
	}

	.location {
		grid-column: 1/3;
		justify-self: left;
	}

	.price {
		grid-column: 3/-1;
		justify-self: right;
	}

	.button {
		grid-column: 1/-1;
		justify-self: center;
	}
}

.button {
	grid-row: -1;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 600;
	cursor: pointer;
	display: block;
	padding: 0 1.5em;
	height: 3em;
	line-height: 2.9em;
	min-width: 3em;
	background-color: transparent;
	border: solid 2px #fff;
	color: #fff;
	border-radius: 4px;
	text-align: center;
	left: 50%;
	backface-visibility: hidden;
	transition: 0.3s ease-in-out;
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);

	&:hover {
		background-color: #fff;
		box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
		text-shadow: none;
		color: $accent-color;
	}

	&.return {
		line-height: 3em;
		color: $accent-color;
		border-color: $accent-color;
		text-shadow: none;

		&:hover {
			background-color: $accent-color;
			color: #fff;
			box-shadow: none;
		}
	}

	&.medium {
		color: $red-color;
		border-color: $red-color;

		&:hover {
			background-color: $red-color;
		}
	}

	&.hard {
		color: #ff0000;
		border-color: #ff0000;

		&:hover {
			background-color: #ff0000;
		}
	}
}

::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: lighten($accent-color, 20%);
}

::-webkit-scrollbar-thumb:hover {
	background: $accent-color;
}