@import "../../global.scss";

.wordcard {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // margin: 8rem;
    min-height: 100vh;
    background-image: linear-gradient(227deg, #ff4e00 0%, #ec9f05 74%);

    h1 {
        margin: 2rem;
        margin-bottom: 0;
        color: #fff;
    }

    p {
        margin: 0.5rem;
        font-size: 1.3rem;
        color: #fff;
        text-align: center;
    }

    .select {
        position: relative;
        min-width: 200px;

        svg {
            position: absolute;
            right: 12px;
            top: calc(50% - 3px);
            width: 10px;
            height: 6px;
            stroke-width: 2px;
            stroke: #ffffff;
            fill: none;
            stroke-linecap: round;
            stroke-linejoin: round;
            pointer-events: none;
        }

        select {
            -webkit-appearance: none;
            padding: 7px 40px 7px 12px;
            width: 100%;
            border: 1px solid #ffffff80;
            // border-radius: 5px;
            background-color: rgba(255, 255, 255, 0.06);
            // border-radius: 15px;
            backdrop-filter: blur(30px);
            box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
            color: #fff;
            box-shadow: 0 1px 3px -2px #9098A9;
            cursor: pointer;
            font-family: inherit;
            font-size: 16px;
            transition: all 150ms ease;

            &:required {
                &:invalid {
                    color: #ffffff;
                }
            }

            option {
                color: #000000;
            }
            

            option[value=""][disabled] {
                display: none;
            }

            &:focus {
                outline: none;
                border-color: rgb(255, 255, 255);
                box-shadow: 0 0 0 2px rgba(0, 119, 255, 0.2);
            }

            &:hover {
                &+svg {
                    stroke: #07f;
                }
            }
        }

    }
    .sprites {
        position: absolute;
        width: 0;
        height: 0;
        pointer-events: none;
        user-select: none;
    }

    button {
        margin: 2rem;
        margin-top: 0;
        font-family: 'Righteous';
        background-color: rgba(255, 255, 255, 0.06);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 15px;
        padding: 1rem;
        backdrop-filter: blur(30px);
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
        color: #fff;

        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }

    .cards {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70%;
        flex-wrap: wrap;

        @include mobile {
            width: 100%;
            // margin: 0.5rem;
        }

        .gamecard {
            position: relative;
            margin: 1rem;
            width: 25%;

            @include mobile {
                width: 45%;
                margin: 0.5rem;
            }

            .cardback {
                font-weight: 400;
                background-color: rgba(255, 255, 255, 0.06);
                border: 1px solid rgba(255, 255, 255, 0.1);
                border-radius: 15px;
                padding: 1rem;
                backdrop-filter: blur(30px);
                box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
                display: flex;
                align-items: center;
                justify-content: center;
                // margin: 5rem;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                transition: all 0.2s ease;
                transition-delay: 0.2s;

                &:hover {
                    background-color: rgba(255, 255, 255, 0.2);
                }
            }

            .cardfront {
                font-weight: 400;
                font-size: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-family: 'Righteous';
                background-color: rgba(255, 255, 255, 0.06);
                border: 1px solid rgba(255, 255, 255, 0.1);
                border-radius: 15px;
                padding: 1rem;
                backdrop-filter: blur(30px);
                box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
                // margin: 5rem;
                height: 100px;
                width: 100%;
                transform: rotateY(90deg);
                transition: all 0.2s ease;

                // position: absolute;
                @include mobile {
                    height: 75px;
                }

                // transform: rotateY(90deg);
            }

        }

        // .matched {
        //     display: none;
        // }

        .flipped {
            .cardback {
                transform: rotateY(90deg);
                transition-delay: 0s;
            }

            .cardfront {
                transform: rotateY(0deg);
                transition-delay: 0.2s;
            }
        }
    }

    .over {
        display: none;
    }
}